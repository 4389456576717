@import 'variables';
@import 'fonts';
@import 'mixins';
@import 'forms';


html {
  position: relative;
  min-height: 100%;
}

body {
  background: #f7f7f9;
  color: $color-main;
  font-family: 'Montserrat', sans-serif;
  font-size: 15px;
  letter-spacing: 0.3px;
  line-height: 1.75rem;
  padding-top: 50px;
  margin-bottom: 50px;
}

.logo {
  height: 50px;
}

h1, .h1 {
  font-size: 30px;
  line-height: 3.2625rem;
}

h2, .h2 {
  font-size: 2rem;
  font-weight: 300;
  line-height: 2.73rem;
}

h3, .h3 {
  font-size: 1.6rem;
  line-height: 2.26rem;
}

h4, .h4 {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.93rem;
}

h5, .h5 {
  font-size: 0.93rem;
  font-weight: 600;
  letter-spacing: 5px;
  line-height: 1.53rem;
  text-transform: uppercase;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
}

.btn:focus {
  @include box-shadow(none);
}

.btn-link {
  color: $color-main;

  &:hover,
  &:focus {
    color: $color-main;
  }
}

.btn-primary {
  background: #77AAAD;
  border-color: #77AAAD;

  &:hover,
  &:focus {
    background: darken(#77AAAD, 10%);
    border-color: darken(#77AAAD, 10%);
  }
}

.btn-fb {
  background: #3b5998;
  color: #fff;

  &:hover,
  &:focus {
    color: #fff;
  }
}

.btn-tw {
  background: #1da1f2;
  color: #fff;

  &:hover,
  &:focus {
    color: #fff;
  }
}

.btn-re {
  background: #ff4500;
  color: #fff;

  &:hover,
  &:focus {
    color: #fff;
  }
}

.btn-li {
  background: #0077b5;
  color: #fff;

  &:hover,
  &:focus {
    color: #fff;
  }
}

.btn-light {
  background: #e2e6ea;
  border-color: #dae0e5;
}

.btn-active {
  background: #e2e6ea;
}

.mb10 {
  margin-bottom: 10px;
}

.mt-10 {
  margin-top: -20px;
}

.no-margin {
  margin: 0;
}

.sweet-modal .sweet-box-actions .sweet-action-close:hover {
  background: #77AAAD !important;
}

.vdp-datepicker__calendar .cell.selected {
  background: #77AAAD !important;
}

.footer {
  a {
    color: $color-main;
    text-decoration: underline;
  }
}


@import 'responsive';

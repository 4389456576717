@mixin box-shadow ($values) {
  -webkit-box-shadow: $values;
     -moz-box-shadow: $values;
          box-shadow: $values;
}

@mixin border-radius ($values) {
  -webkit-border-radius: $values;
     -moz-border-radius: $values;
          border-radius: $values;
}

@mixin appearance ($value) {
  -webkit-appearance: $value;
     -moz-appearance: $value;
          appearance: $value;
}

@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }
  @-ms-keyframes #{$animation-name} {
    @content;
  }
  @-o-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};
}

@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

@mixin linear-gradient ($fromColor, $toColor) {
  background-color: $toColor; /* Fallback Color */
  background-image: -webkit-gradient(linear, left top, left bottom, from($fromColor), to($toColor)); /* Saf4+, Chrome */
  background-image: -webkit-linear-gradient(top, $fromColor, $toColor); /* Chrome 10+, Saf5.1+, iOS 5+ */
  background-image:    -moz-linear-gradient(top, $fromColor, $toColor); /* FF3.6 */
  background-image:     -ms-linear-gradient(top, $fromColor, $toColor); /* IE10 */
  background-image:      -o-linear-gradient(top, $fromColor, $toColor); /* Opera 11.10+ */
  background-image:         linear-gradient(top, $fromColor, $toColor);
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,StartColorStr='#{$fromColor}', EndColorStr='#{$toColor}');
}

@mixin gradient($gradient-degrees, $fromColor, $toColor) {
  background-color: $toColor; /* Fallback Color */
  background-image: -webkit-gradient(linear, left top, left bottom, from($fromColor), to($toColor)); /* Saf4+, Chrome */
  background-image: -webkit-linear-gradient($gradient-degrees, $fromColor, $toColor); /* Chrome 10+, Saf5.1+, iOS 5+ */
  background-image:    -moz-linear-gradient($gradient-degrees, $fromColor, $toColor); /* FF3.6 */
  background-image:     -ms-linear-gradient($gradient-degrees, $fromColor, $toColor); /* IE10 */
  background-image:      -o-linear-gradient($gradient-degrees, $fromColor, $toColor); /* Opera 11.10+ */
  background-image:         linear-gradient($gradient-degrees, $fromColor, $toColor);
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,StartColorStr='#{$fromColor}', EndColorStr='#{$toColor}');
}

@mixin opacity($opacity) {
  filter: alpha(opactiy=($opacity * 100));
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=#{$opacity * 100})";
  -moz-opacity: $opacity;
  -khtml-opacity: $opacity;
  opacity: $opacity;
}

@mixin at2x($image_name, $w: auto, $h: auto, $extention: '.png') {
  background-image: url($image_name + $extention);
  background-size: $w $h;
  $x2img : $image_name + '@2x' + $extention;

  @media all and (-webkit-min-device-pixel-ratio : 1.5) {
    background-image: url($x2img);
    background-size: $w $h;
  }
}

@mixin vertical-align($position: relative) {
  position: $position;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

@mixin transform($transforms) {
  -moz-transform: $transforms;
  -o-transform: $transforms;
  -ms-transform: $transforms;
  -webkit-transform: $transforms;
  transform: $transforms;
}

@mixin filter($filter-type,$filter-amount) {
  -webkit-filter: $filter-type+unquote('(#{$filter-amount})');
  -moz-filter: $filter-type+unquote('(#{$filter-amount})');
  -ms-filter: $filter-type+unquote('(#{$filter-amount})');
  -o-filter: $filter-type+unquote('(#{$filter-amount})');
  filter: $filter-type+unquote('(#{$filter-amount})');
}

@mixin placeholder {
  ::-webkit-input-placeholder {@content}
  :-moz-placeholder           {@content}
  ::-moz-placeholder          {@content}
  :-ms-input-placeholder      {@content}
}

.week {
  width: 15px;
  height: 15px;
  border-style: solid;
  border-color: #ccc;
  border-width: 1px;
  margin-bottom: 5px;
  background: #fff;
  text-align: left;
}
.week-content {
  cursor: pointer;
  width: 15px;
  height: 15px;
}
.year {
  font-size: 13px;
  height: 15px;
  width: 15px;
  line-height: 15px;
  text-align: right;
}
.active {
  background: #F16B6F;
  border-color: #F16B6F;
}
.color {
  border-radius: 4px;
  border: 1px solid transparent;
  cursor: -webkit-grab;
  cursor: grab;
  text-align: center;
}
.color1 {
  background: #77AAAD;
  border-color: #77AAAD;
}
.color2 {
  background: #8EC0E4;
  border-color: #8EC0E4;
}
.color3 {
  background: #D8E6E7;
  border-color: #D8E6E7;
}
.color4 {
  background: #FDD692;
  border-color: #FDD692;
}
.color5 {
  background: #fff;
  border: 1px solid #ccc;
}
.color6 {
  background: #D499B9;
  border: 1px solid #D499B9;
}
.color7 {
  background: #F17F42;
  border: 1px solid #F17F42;
}
.color8 {
  background: #8EC0E4;
  border: 1px solid #8EC0E4;
}
.pointer {
  cursor: pointer;
}

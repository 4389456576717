@media only screen and (max-width : 479px) {
}

@media only screen and (max-width : 767px) {
  .week {
    height: 5px !important;
    width: 5px !important;
  }
}

@media (min-width: 576px) {

}

@media only screen and (min-width : 768px) {

}

@media (min-width : 768px) and (max-width : 991px){

}

/* Medium Devices, Desktops */
@media only screen and (max-width : 991px) {

}

@media (min-width: 992px) {

}

@media (min-width: 1200px){

}
